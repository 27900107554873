<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="d-flex gap-4 align-items-start mb-2">
                    <!-- 販売番号 -->
                    <div style="width: 6rem">
                        <label
                            for="SALES_NO"
                            class="form-label form-label-sm"
                            >{{ dg.SalTSales.SALES_NO }}</label
                        >
                        <input
                            readonly
                            name="SALES_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="SALES_NO"
                            :value="form.SALES_NO"
                        />
                        <error-message :error="errors.SALES_NO"></error-message>
                    </div>
                    <!-- 状態 -->
                    <div>
                        <label for="STATUS" class="form-label">{{
                            dg.SalTSales.STATUS
                        }}</label>
                        <select
                            name="STATUS"
                            class="form-select form-select-sm"
                            id="STATUS"
                            v-model="form.STATUS"
                        >
                            <option
                                v-for="(
                                    salesStatus, index
                                ) in selectSalesStatusList"
                                :key="index"
                                :value="index"
                            >
                                {{ salesStatus }}
                            </option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>
                    <!-- 終了予約 -->
                    <div
                        class="align-self-center"
                        style="margin-top: 1rem; margin-left: -1rem"
                    >
                        <toggle-switch
                            v-model="form.IS_SCHEDULED_TO_END"
                            :id="'IS_SCHEDULED_TO_END'"
                        >
                            {{ dg.SalTSales.IS_SCHEDULED_TO_END }}
                        </toggle-switch>
                    </div>
                    <!-- 販売日 -->
                    <input-date
                        v-model="form.SALES_DATE"
                        :error="errors.SALES_DATE"
                        :label-attr="{
                            for: 'SALES_DATE',
                            title: dg.SalTSales.SALES_DATE,
                        }"
                    ></input-date>
                    <!-- 関連見積番号-枝番 -->
                    <div style="width: 7rem">
                        <label for="EST_NO" class="form-label form-label-sm">{{
                            dg.SalTSales.EST_NO
                        }}</label>
                        <input
                            readonly
                            name="EST_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="EST_NO"
                            :value="estNo"
                        />
                        <error-message :error="errors.EST_NO"></error-message>
                    </div>
                    <input name="EST_ID" type="hidden" v-model="form.EST_ID" />
                    <!-- 関連請求番号-枝番 -->
                    <div style="width: 7rem">
                        <label for="INV_NO" class="form-label form-label-sm">{{
                            dg.SalTSales.INV_NO
                        }}</label>
                        <input
                            readonly
                            name="INV_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="INV_NO"
                            :value="invNo"
                        />
                        <error-message :error="errors.INV_NO"></error-message>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlag"
                        :errors="errors"
                        nameAttribute="KOKYK_ID"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>
                    <!-- 顧客名 -->
                    <div class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.MKokykLic.CLNIC_NM
                        }}</label>
                        <input
                            type="text"
                            name="CLNIC_NM"
                            class="form-control form-control-sm"
                            id="CLNIC_NM"
                            v-model="form.CLNIC_NM"
                        />
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                    <!-- 請求名称 -->
                    <div class="col-2">
                        <label for="INV_NM" class="form-label">{{
                            dg.SalTSales.INV_NM
                        }}</label>
                        <input
                            type="text"
                            name="INV_NM"
                            class="form-control form-control-sm"
                            id="INV_NM"
                            v-model="form.INV_NM"
                        />
                        <error-message :error="errors.INV_NM"></error-message>
                    </div>
                    <!-- 郵便番号 -->
                    <div class="col-1">
                        <label for="ZIP_CD" class="form-label">{{
                            dg.MKokykLic.ZIP_CD
                        }}</label>
                        <input-hankaku
                            v-model="form.ZIP_CD"
                            inputType="text"
                            inputName="ZIP_CD"
                            inputId="ZIP_CD"
                        >
                        </input-hankaku>
                        <error-message :error="errors.ZIP_CD"></error-message>
                    </div>
                    <!-- 住所 -->
                    <div class="col-3">
                        <label for="ADR" class="form-label">{{
                            dg.MKokykLic.ADR
                        }}</label>
                        <input
                            type="text"
                            name="ADR"
                            class="form-control form-control-sm"
                            id="ADR"
                            v-model="form.ADR"
                        />
                        <error-message :error="errors.ADR"></error-message>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- 契約種別 -->
                    <div class="col-auto">
                        <label for="CONT_KIND" class="form-label">{{
                            dg.SalTSales.CONT_KIND
                        }}</label>
                        <select
                            name="CONT_KIND"
                            class="form-select form-select-sm"
                            id="CONT_KIND"
                            v-model="form.CONT_KIND"
                            v-on:change="onChangeContKind"
                        >
                            <option
                                v-for="(contKind, index) in contKindList"
                                :key="index"
                                :value="index"
                            >
                                {{ contKind }}
                            </option>
                        </select>
                        <error-message
                            :error="errors.CONT_KIND"
                        ></error-message>
                    </div>
                    <!-- 請求方法 -->
                    <div class="col-auto">
                        <label for="RECE_CODE" class="form-label">{{
                            dg.SalTSales.RECE_CODE
                        }}</label>
                        <select
                            name="RECE_CODE"
                            class="form-select form-select-sm"
                            id="RECE_CODE"
                            v-model="form.RECE_CODE"
                        >
                            <option
                                v-for="(receCode, index) in Object.keys(
                                    receCodeList
                                ).sort()"
                                :key="index"
                                :value="receCode"
                            >
                                {{ receCodeList[receCode] }}
                            </option>
                        </select>
                        <error-message
                            :error="errors.RECE_CODE"
                        ></error-message>
                    </div>
                    <!-- 請求開始年月 -->
                    <div class="col-2">
                        <label for="INV_SDATE" class="form-label">{{
                            dg.SalTSales.INV_SDATE
                        }}</label>
                        <div class="d-flex align-items-start">
                            <input
                                type="month"
                                name="INV_SDATE"
                                class="form-control form-control-sm"
                                style="width: 160px"
                                id="INV_SDATE"
                                v-model="form.INV_SDATE"
                            />
                            <span
                                class="form-text ms-1"
                                style="margin-top: -11px"
                                >{{ dg.SalTSales.INV_SDATE_help }}</span
                            >
                        </div>
                        <error-message
                            :error="errors.INV_SDATE"
                        ></error-message>
                    </div>
                    <!-- 次回請求年月 --><!-- 引落請求月 -->
                    <div class="col-auto border" style="width: 950px">
                        <label for="RECE_NDATE" class="form-label"
                            >{{ dg.SalTSales.RECE_NDATE }}　または　</label
                        >
                        <label for="DRAW_DATE" class="form-label">{{
                            dg.SalTSales.DRAW_DATE
                        }}</label>
                        <span class="form-text ms-1">{{
                            dg.SalTSales.DRAW_DATE_help
                        }}</span>
                        <div class="row">
                            <div class="col-2">
                                <input
                                    type="month"
                                    name="RECE_NDATE"
                                    class="form-control form-control-sm"
                                    id="RECE_NDATE"
                                    v-model="form.RECE_NDATE"
                                />
                            </div>
                            <div class="col-10">
                                <div
                                    class="form-check form-check-inline"
                                    v-for="(month, index) in [...Array(12)].map(
                                        (_, i) => i + 1
                                    )"
                                    :key="index"
                                >
                                    <input
                                        class="form-check-input"
                                        name="DRAW_DATE[]"
                                        type="checkbox"
                                        :id="`DRAW_DATE${month}`"
                                        :value="month"
                                        v-model="form.DRAW_DATE"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="`DRAW_DATE${month}`"
                                        >{{ month }}月</label
                                    >
                                </div>
                            </div>
                        </div>
                        <error-message
                            :error="errors.RECE_NDATE"
                        ></error-message>
                        <error-message
                            :error="errors.DRAW_DATE"
                        ></error-message>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- 請求先顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.B_KOKYK_ID"
                        @set-customer="setBCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlag"
                        :errors="errors"
                        nameAttribute="B_KOKYK_ID"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>
                    <!-- 請求先顧客名 -->
                    <div class="col-2">
                        <label for="B_CLNIC_NM" class="form-label">{{
                            dg.SalTSales.B_CLNIC_NM
                        }}</label>
                        <div>
                            <input
                                type="text"
                                name="B_CLNIC_NM"
                                class="form-control form-control-sm d-inline"
                                style="width: 210px"
                                id="B_CLNIC_NM"
                                v-model="form.B_CLNIC_NM"
                            />
                            <!-- <span class="form-text ms-1">{{ dg.SalTSales.B_CLNIC_NM_help}}</span> -->
                        </div>
                        <error-message
                            :error="errors.B_CLNIC_NM"
                        ></error-message>
                    </div>
                    <!-- 手数料支払先 -->
                    <div class="col-2">
                        <label for="COMM_KIND" class="form-label">{{
                            dg.SalTSales.COMM_KIND
                        }}</label>
                        <select
                            name="COMM_KIND"
                            class="form-select form-select-sm"
                            id="COMM_KIND"
                            v-model="form.COMM_KIND"
                        >
                            <option value=""></option>
                            <option
                                v-for="(commKind, index) in commKindList"
                                :key="index"
                                :value="index"
                            >
                                {{ commKind }}
                            </option>
                        </select>
                        <error-message
                            :error="errors.COMM_KIND"
                        ></error-message>
                    </div>
                    <!-- 手数料支払予定年月 -->
                    <div class="col-4">
                        <label for="COMM_DATE" class="form-label">{{
                            dg.SalTSales.COMM_DATE
                        }}</label>
                        <div class="d-flex align-items-start">
                            <input
                                type="month"
                                name="COMM_DATE"
                                class="form-control form-control-sm d-inline"
                                style="width: 210px"
                                id="COMM_DATE"
                                v-model="form.COMM_DATE"
                            />
                            <span class="form-text ms-1">{{
                                dg.SalTSales.COMM_DATE_help
                            }}</span>
                        </div>
                        <error-message
                            :error="errors.COMM_DATE"
                        ></error-message>
                    </div>
                </div>
                <div
                    class="mb-2 row"
                    v-if="form.CONT_KIND == Object.keys(contKindList)[1]"
                >
                    <!-- 契約開始日 -->
                    <div class="col-2">
                        <label for="CONT_START" class="form-label">{{
                            dg.SalTSales.CONT_START
                        }}</label>
                        <input
                            type="date"
                            name="CONT_START"
                            class="form-control form-control-sm"
                            id="CONT_START"
                            v-model="form.CONT_START"
                        />
                        <error-message
                            :error="errors.CONT_START"
                        ></error-message>
                    </div>
                    <!-- 契約終了日 -->
                    <div class="col-2">
                        <label for="CONT_END" class="form-label">{{
                            dg.SalTSales.CONT_END
                        }}</label>
                        <input
                            type="date"
                            name="CONT_END"
                            class="form-control form-control-sm"
                            id="CONT_END"
                            v-model="form.CONT_END"
                        />
                        <error-message :error="errors.CONT_END"></error-message>
                    </div>
                    <!-- 契約期間 -->
                    <div class="col-1">
                        <label for="CONT_PERIOD" class="form-label">{{
                            dg.SalTSales.CONT_PERIOD
                        }}</label>
                        <input-hankaku
                            v-model="form.CONT_PERIOD"
                            inputType="text"
                            inputName="CONT_PERIOD"
                            inputId="CONT_PERIOD"
                        ></input-hankaku>
                        <error-message
                            :error="errors.CONT_PERIOD"
                        ></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 備考1 -->
                    <div class="col-6">
                        <label for="SALES_NOTE1" class="form-label">{{
                            dg.SalTSales.SALES_NOTE1
                        }}</label>
                        <textarea
                            name="SALES_NOTE1"
                            class="form-control form-control-sm"
                            id="SALES_NOTE1"
                            v-model="form.SALES_NOTE1"
                            rows="5"
                        ></textarea>
                    </div>
                    <!-- 備考2 -->
                    <div class="col-6">
                        <label for="SALES_NOTE2" class="form-label">{{
                            dg.SalTSales.SALES_NOTE2
                        }}</label>
                        <textarea
                            name="SALES_NOTE2"
                            class="form-control form-control-sm"
                            id="SALES_NOTE2"
                            v-model="form.SALES_NOTE2"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-6">
                        <!-- ファイル -->
                        <file-upload
                            v-model="form"
                            v-model:filesModelValue="files"
                            :dg="dg"
                            :max-file-count="3"
                            :slice-num="25"
                            :document-root="documentRoot"
                        ></file-upload>
                    </div>
                </div>
                <div class="table-responsive">
                    <table
                        class="table table-sm mb-0"
                        style="table-layout: fixed"
                    >
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 1rem">
                                    {{ dg.SequentialNumber }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTSalesDtl.PRODUCT_CODE }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTSalesDtl.PRODUCT_NAME }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 3rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTSalesDtl.UPRICE }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 2rem;
                                        letter-spacing: 0.2rem;
                                        text-indent: 0.2rem;
                                    "
                                >
                                    {{ dg.SalTSalesDtl.CNT }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 3rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTSalesDtl.PRICE }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTSalesDtl.NOTE1 }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTSalesDtl.NOTE2 }}
                                </th>
                                <th
                                    v-if="edit"
                                    class="text-center"
                                    style="width: 2.2rem"
                                ></th>
                            </tr>
                        </thead>
                        <tbody>
                            <sales-detail-input
                                ref="detail"
                                v-for="(detail, index) in form.salTSalesDtls"
                                :key="index"
                                v-model="form.salTSalesDtls[index]"
                                :dg="dg"
                                :index="index"
                                :product-obj-list="productObjList"
                                :edit="edit"
                                @stock-for-detail="stockForDetail"
                                @update:price="calcTotalPrice"
                                @splice-row="spliceRow"
                            >
                            </sales-detail-input>
                            <tr>
                                <!-- 小計 -->
                                <th colspan="3" class="border-bottom-0"></th>
                                <th
                                    colspan="2"
                                    class="border-bottom-0"
                                    style="width: 4rem; letter-spacing: 1.5rem"
                                >
                                    {{ dg.SUBTOTAL }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{
                                        Number(form.PRICE).toLocaleString()
                                    }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                            <tr>
                                <!-- 消費税 -->
                                <th colspan="3" class="border-bottom-0"></th>
                                <th colspan="2" class="border-bottom-0">
                                    {{ dg.SalTEstimate.TAX }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{ Number(form.TAX).toLocaleString() }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                            <tr>
                                <!-- 合計金額 -->
                                <th colspan="3" class="border-bottom-0"></th>
                                <th colspan="2" class="border-bottom-0">
                                    {{ dg.SalTEstimate.TPRICE }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{
                                        Number(form.TPRICE).toLocaleString()
                                    }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card mb-1" v-if="stockList.length">
            <div class="card-body py-1">
                <stock-index-table
                    v-model="stockList"
                    :dg="dg"
                    :document-root="documentRoot"
                    :stock-status-list="stockStatusList"
                    :sort="{ key: 'SALES_DATE', asc: false }"
                    divHeight="auto"
                ></stock-index-table>
            </div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- 通知ボタン -->
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="notify"
            >
                {{ dg.Notify }}
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="stock"
            >
                仕入
            </button>
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit && (!contKind || form.RECE_CODE === '00001')"
                @click="invoice"
            >
                請求
            </button>
            <!-- コピーボタン -->
            <copy-button
                v-if="edit"
                :alert-flag="changed"
                :alert-message="dg.changedMessage"
                :confirm-message="`販売番号${
                    form.SALES_NO + dg.copyConfirmMessage
                }`"
                :url="`${documentRoot}/sales/${form.SALES_NO}/copy`"
            ></copy-button>
            <button
                type="button"
                class="btn btn-danger"
                v-if="edit"
                @click="destroy"
            >
                削除
            </button>
            <!-- 中止ボタン -->
            <button type="button" class="btn btn-primary" @click="cancel">
                中止
            </button>
            <button type="button" class="btn btn-primary" @click="register">
                登録
            </button>
        </div>

        <modal-customer
            ref="modal"
            :dg="dg"
            :del-flag="delFlag"
            :syzk-list="syzkList"
            @set-customer="setCustomerInfo"
        >
        </modal-customer>

        <modal-dialog ref="dialog" :dg="dg" @update="update"></modal-dialog>
    </form>
</template>

<script>
import moment from "moment";
import ModalCustomer from "../ModalCustomer.vue";
import CopyButton from "../buttons/CopyButton.vue";
import CustomerSearchButton from "../buttons/CustomerSearchButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import InputDate from "../inputs/InputDate.vue";
import InputHankaku from "../inputs/InputHankaku.vue";
import InputKokykId from "../inputs/InputKokykId.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import ToggleSwitch from "../inputs/ToggleSwitch.vue";
import ModalDialog from "../modals/ModalDialog.vue";
import StockIndexTable from "../stock/StockIndexTable.vue";
import SalesDetailInput from "./SalesDetailInput.vue";

export default {
    name: "SalesInput",
    components: {
        CustomerSearchButton,
        CopyButton,
        FileUpload,
        InputDate,
        InputKokykId,
        InputKokykIdWithButton,
        InputHankaku,
        ModalCustomer,
        ModalDialog,
        SalesDetailInput,
        StockIndexTable,
        ToggleSwitch,
    },
    props: {
        contKind: { type: Number, defaule: 0 },
        commKindList: Object,
        contKindList: Object,
        data: Object,
        delFlag: Object,
        dg: Object,
        documentRoot: String,
        productObjList: Object,
        receCodeList: Object,
        salesContractStatusList: Object,
        invoiceStatusList: Object,
        salesStatusList: Object,
        stockStatusList: Object,
        syzkList: Object,
    },
    data() {
        return {
            changed: false,
            edit: false,
            errors: [],
            files: [],
            form: {},
            stockList: [],
            oldBKokykId: "",
        };
    },
    created() {
        this.form = this.data;
        this.setSalTSalesDtls();
        this.edit = this.data.SALES_NO ? true : false;
        this.init();
    },
    mounted() {
        const unwatch = this.$watch(
            "form",
            (newVal) => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    methods: {
        init() {
            this.form.SALES_DATE =
                this.form.SALES_DATE || moment().format("YYYY-MM-DD");
            this.form.CONT_START =
                this.form.CONT_START || moment().format("YYYY-MM-DD");
            this.form.INV_NM = this.form.INV_NM || this.form.CLNIC_NM;
            this.form.STATUS =
                this.form.STATUS || Object.keys(this.invoiceStatusList)[0];
            this.form.RECE_CODE = this.form.RECE_CODE || "00001";
            this.form.CONT_KIND =
                this.form.CONT_KIND ||
                // 単品
                Object.keys(this.contKindList)[0];
            this.form.IS_SCHEDULED_TO_END = Boolean(
                this.form.IS_SCHEDULED_TO_END
            );
            this.getStockList();
            this.oldBKokykId = this.form.B_KOKYK_ID;
        },
        getStockList() {
            if (!this.form.SALES_NO) {
                return;
            }
            axios
                .get(`/stock/search`, {
                    params: { SALES_NO: this.form.SALES_NO },
                })
                .then((res) => {
                    this.stockList = res.data.data;
                });
        },
        spliceRow(index) {
            this.form.salTSalesDtls.splice(index, 1);

            const keys = [
                "RNO",
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "PRICE",
                "NOTE1",
            ];
            const defaultDetail = keys.reduce(
                (obj, key) => ({ ...obj, [key]: "" }),
                {}
            );
            this.form.salTSalesDtls.push({ ...defaultDetail });
        },
        setSalTSalesDtls() {
            let length = this.data.salTSalesDtls.length;

            const keys = [
                "RNO",
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "PRICE",
                "NOTE1",
            ];
            const defaultDetail = keys.reduce(
                (obj, key) => ({ ...obj, [key]: "" }),
                {}
            );

            while (length < 10) {
                length = this.form.salTSalesDtls.push({ ...defaultDetail });
            }
        },
        filterValidRow() {
            const checKeys = [
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "NOTE1",
            ];
            let row = 1;

            for (let i = 9; i > 0; i--) {
                checKeys.some((check) => {
                    if (this.form.salTSalesDtls[i][check]) {
                        row = i + 1;
                        return true;
                    }
                });
                if (row > 1) {
                    break;
                }
            }

            return row;
        },
        makeData() {
            let data = Object.assign({}, this.form);
            data.salTSalesDtls = data.salTSalesDtls.slice(
                0,
                this.filterValidRow()
            );

            return data;
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);
            let data = this.makeData();
            if (!formData.has("DRAW_DATE[]")) {
                formData.set("DRAW_DATE", "");
            }
            formData.set("IS_SCHEDULED_TO_END", this.form.IS_SCHEDULED_TO_END);

            if (this.form.CONT_KIND === 0) {
                const contOnlyKeyList = [
                    "CONT_START",
                    "CONT_END",
                    "CONT_PERIOD",
                ];

                contOnlyKeyList.forEach((key) => {
                    formData.set(key, "");
                });
            }

            Object.keys(data).forEach(function (key) {
                if (key == "salTSalesDtls") {
                    data[key].forEach((item, index) => {
                        Object.keys(item).forEach(function (i) {
                            if (item[i] == null) {
                                formData.set(
                                    `salTSalesDtls[${index}][${i}]`,
                                    ""
                                );
                            } else {
                                formData.set(
                                    `salTSalesDtls[${index}][${i}]`,
                                    item[i]
                                );
                            }
                        });
                    });
                } else if (["PRICE", "TAX", "TPRICE"].includes(key)) {
                    formData.set(key, data[key]);
                }
            });

            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");

            for (var i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        create() {
            if (!this.bKokykConfirm()) return;

            const formData = this.getFormData();

            axios
                .post(`/sales`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        update() {
            if (!this.bKokykConfirm()) return;

            const formData = this.getFormData();

            axios
                .post(`/sales/${this.form.SALES_NO}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "X-HTTP-Method-Override": "PUT",
                    },
                })
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        invoice() {
            if (this.changed) {
                alert(this.dg.changedMessage);
                return;
            } else {
                window.open(
                    `${this.documentRoot}/invoice/sales?SALES_NO[]=${this.form.SALES_NO}`,
                    "_blank"
                );
            }
        },
        stock() {
            if (this.changed) {
                alert(this.dg.changedMessage);
                return;
            } else {
                window.open(
                    `${this.documentRoot}/stock/create?SALES_NO=${this.form.SALES_NO}`,
                    "_blank"
                );
            }
        },
        stockForDetail(rno) {
            if (this.changed) {
                alert(this.dg.changedMessage);
                return;
            } else {
                window.open(
                    `${this.documentRoot}/stock/create?SALES_NO=${this.form.SALES_NO}&RNO=${rno}`,
                    "_blank"
                );
            }
        },
        bKokykConfirm() {
            return this.isInvOrDraw &&
                this.form.B_KOKYK_ID &&
                this.oldBKokykId !== this.form.B_KOKYK_ID
                ? window.confirm(
                      `${this.form.B_CLNIC_NM}(${this.form.B_KOKYK_ID})${this.dg.SalTSales.bKokykMessage}`
                  )
                : true;
        },
        checkDrawDate() {
            const regex = /^[^2-3]/; // 請求方法が社団/代理店以外（2か3で始まらない）

            // 請求方法が社団/代理店以外
            if (regex.test(this.form.RECE_CODE)) {
                // 請求月か次回請求年月のいずれにも入力が無い場合、ダイアログ
                return this.form.DRAW_DATE.length === 0 && !this.form.RECE_NDATE
                    ? window.confirm(
                          this.dg.SalTSales.noDrawDateAndReceNDateMessage
                      )
                    : true;
                // 請求方法が社団/代理店
            } else {
                // 請求月か次回請求年月の一方/両方に入力がある場合、ダイアログ
                return this.form.DRAW_DATE.length > 0 || this.form.RECE_NDATE
                    ? window.confirm(
                          this.dg.SalTSales.hasDrawDateOrReceNDateMessage
                      )
                    : true;
            }
        },
        register() {
            if (!this.checkDrawDate()) return;
            this.edit ? this.$refs.dialog.open() : this.create();
        },
        cancel() {
            if (this.changed) {
                if (!confirm(this.dg.cancelMessage)) {
                    return;
                }
            }
            if (history.length === 1) {
                window.close();
                return;
            }
            if (document.referrer.lastIndexOf("unclaimed") !== -1) {
                location.href = document.referrer;
                return;
            }
            if (this.edit) {
                location.href = `${this.documentRoot}/sales`;
            } else {
                location.href = `${this.documentRoot}/sales`;
            }
        },
        destroy() {
            if (confirm(this.dg.deleteConfirmMessage)) {
                axios.delete(`/sales/${this.form.SALES_NO}`).then((res) => {
                    location.href = `${this.documentRoot}/sales`;
                });
            }
        },
        open() {
            this.$refs.modal.open();
        },
        setCustomerInfo(mKokykLic) {
            const keys = ["KOKYK_ID", "CLNIC_NM", "ZIP_CD", "ADR"];

            // 請求名称がデフォルトの顧客名から編集されているかどうかを確認する
            const isChanged = this.form.CLNIC_NM !== this.form.INV_NM;

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
            // 請求名称にも顧客名をセットする
            // 請求名称が編集されている場合、編集済みの請求名称をそのまま使う
            this.form.INV_NM = isChanged
                ? this.form.INV_NM
                : mKokykLic.CLNIC_NM;
        },
        setBCustomerInfo(mKokykLic) {
            this.form.B_KOKYK_ID = mKokykLic.KOKYK_ID;
            this.form.B_CLNIC_NM = mKokykLic.CLNIC_NM;
        },
        calcTotalPrice() {
            const price = this.form.salTSalesDtls.reduce((acc, cur) => {
                return (acc += Number(cur.PRICE));
            }, 0);

            this.form.PRICE = price;
            this.form.TAX = Math.round(price * 0.1);
            this.form.TPRICE = Math.round(price * 1.1);
        },
        notify() {
            axios
                .post(`/sales/${this.form.SALES_NO}/notify`)
                .then((response) => {
                    alert("slackに通知しました");
                });
        },
        onChangeContKind() {
            this.form.STATUS = Object.keys(this.selectSalesStatusList)[0];
        },
    },
    computed: {
        estNo() {
            return this.form.salTEstimate?.EST_NO && this.form.salTEstimate?.ENO
                ? `${this.form.salTEstimate?.EST_NO}-${this.form.salTEstimate?.ENO}`
                : null;
        },
        invNo() {
            return this.form.salTInvoice?.INV_NO && this.form.salTInvoice?.ENO
                ? `${this.form.salTInvoice.INV_NO}-${this.form.salTInvoice.ENO}`
                : null;
        },
        isInvOrDraw() {
            const targetReceCode = ["00001", "00010"]; // 請求方法：請求(00001)、引落(00010)

            return targetReceCode.includes(this.form.RECE_CODE);
        },
        selectSalesStatusList() {
            return this.form.CONT_KIND == Object.keys(this.contKindList)[0]
                ? this.invoiceStatusList
                : this.salesContractStatusList;
        },
    },
};
</script>
