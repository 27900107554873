<template>
    <div class="col-auto form-check form-switch">
        <div style="margin-left: 15px">
            <input
                class="form-check-input"
                type="checkbox"
                :id="id"
                v-model="modelVal"
                @change="handleToggleChange(modelVal)"
                :disabled="disabled"
            />
            <label class="form-check-label" :for="id"><slot></slot></label>
        </div>
    </div>
</template>
<script>
export default {
    name: "ToggleSwitch",
    emits: ["handle-toggle-change", "update:model-value"],
    props: {
        modelValue: Boolean,
        id: {
            type: String,
            default: "show-detail",
        },
        disabled: Boolean,
    },
    methods: {
        handleToggleChange(toggleVal) {
            this.$emit("handle-toggle-change", toggleVal);
        },
    },
    computed: {
        modelVal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:model-value", value);
            },
        },
    },
};
</script>
