<template>
    <form ref="form" class="mb-2" @submit.prevent="getInvoicesOrDetails()">
        <div class="card">
            <div class="card-body">
                <!-- 検索フォーム（上段）-->
                <div class="d-flex gap-1 align-items-start">
                    <!-- 開始日 -->
                    <input-date
                        style="width: 130px"
                        v-model="form.START_DAY"
                        :error="errors.START_DAY"
                        :label-attr="{ for: 'START_DAY', title: dg.START_DAY }"
                    ></input-date>
                    <!-- 終了日 -->
                    <input-date
                        style="width: 130px"
                        v-model="form.END_DAY"
                        :error="errors.END_DAY"
                        :label-attr="{ for: 'END_DAY', title: dg.END_DAY }"
                    ></input-date>
                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlag"
                        :errors="errors"
                        :syzk-list="syzkList"
                        :divStyle="{ width: '110px' }"
                    ></input-kokyk-id-with-button>
                    <!-- 顧客名 -->
                    <div style="width: 160px; z-index: 1029">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.MKokykLic.CLNIC_NM
                        }}</label>
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.CLNIC_NM"
                        />
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                    <!-- 所属団体 -->
                    <div style="width: 160px; z-index: 1029">
                        <label for="SYZK_NO" class="form-label">{{
                            dg.MsSyzk.SYZK_NM
                        }}</label>
                        <VueMultiselect
                            v-model="form.SYZK_NO"
                            :custom-label="setSyzkLabel"
                            :options="syzkListArray"
                            label="name"
                            :close-on-select="false"
                            :taggable="false"
                            :multiple="true"
                            :searchable="false"
                            placeholder="未選択"
                            selectLabel="選択"
                            selectedLabel="選択済"
                            deselectLabel="解除"
                            :limitText="(count) => `+${count}`"
                            :limit="1"
                        ></VueMultiselect>
                    </div>
                    <!-- 都道府県 -->
                    <div style="width: 160px; z-index: 1029">
                        <label for="PREFECTURE" class="form-label">{{
                            dg.SalTSales.PREFECTURE
                        }}</label>
                        <VueMultiselect
                            v-model="form.PREFECTURE"
                            :custom-label="setPrefectureLabel"
                            :options="prefectureListArray"
                            label="name"
                            :close-on-select="false"
                            :taggable="false"
                            :multiple="true"
                            :searchable="false"
                            placeholder="未選択"
                            selectLabel="選択"
                            selectedLabel="選択済"
                            deselectLabel="解除"
                            :limitText="(count) => `+${count}`"
                            :limit="1"
                        >
                        </VueMultiselect>
                        <error-message
                            :error="errors.PREFECTURE"
                        ></error-message>
                    </div>
                    <!-- 状態 -->
                    <div style="width: 140px; z-index: 1029">
                        <label for="STATUS" class="form-label">{{
                            dg.SalTSales.STATUS
                        }}</label>
                        <VueMultiselect
                            v-model="form.STATUS"
                            :custom-label="setStatusLabel"
                            :options="statusListArray"
                            label="name"
                            :close-on-select="false"
                            :taggable="false"
                            :multiple="true"
                            :searchable="false"
                            placeholder="未選択"
                            selectLabel="選択"
                            selectedLabel="選択済"
                            deselectLabel="解除"
                            :limitText="(count) => `+${count}`"
                            :limit="1"
                        >
                        </VueMultiselect>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>
                    <!-- 再請求 -->
                    <div
                        class="align-self-center"
                        style="margin: 1rem 0.5rem 0 -0.75rem"
                    >
                        <toggle-switch
                            :id="'IS_RECLAIMING'"
                            v-model="form.IS_RECLAIMING"
                        >
                            {{ dg.SalTInvoice.IS_RECLAIMING }}
                        </toggle-switch>
                    </div>
                    <!-- 請求方法 -->
                    <div style="width: 150px; z-index: 1029">
                        <label for="RECE_CODE" class="form-label">{{
                            dg.SalTSales.RECE_CODE
                        }}</label>
                        <VueMultiselect
                            v-model="form.RECE_CODE"
                            :custom-label="setReceCodeLabel"
                            :options="receCodeListArray"
                            label="name"
                            :close-on-select="false"
                            :taggable="false"
                            :multiple="true"
                            :searchable="false"
                            placeholder="未選択"
                            selectLabel="選択"
                            selectedLabel="選択済"
                            deselectLabel="解除"
                            :limitText="(count) => `+${count}`"
                            :limit="1"
                        >
                        </VueMultiselect>
                        <error-message
                            :error="errors.RECE_CODE"
                        ></error-message>
                    </div>
                    <!-- 入金方法 -->
                    <div style="width: 150px; z-index: 1029">
                        <label for="PAY_KIND" class="form-label">{{
                            dg.SalTInvoice.PAY_KIND
                        }}</label>
                        <VueMultiselect
                            v-model="form.PAY_KIND"
                            :custom-label="setPayKindLabel"
                            :options="payKindListArray"
                            label="name"
                            :close-on-select="false"
                            :taggable="false"
                            :multiple="true"
                            :searchable="false"
                            placeholder="未選択"
                            selectLabel="選択"
                            selectedLabel="選択済"
                            deselectLabel="解除"
                            :limitText="(count) => `+${count}`"
                            :limit="1"
                        >
                        </VueMultiselect>
                        <error-message
                            :error="errors.RECE_CODE"
                        ></error-message>
                    </div>
                </div>
                <!-- 検索フォーム（下段）-->
                <div class="d-flex gap-1 align-items-start">
                    <!-- 合計金額 -->
                    <div style="width: 240px; z-index: 1028">
                        <label for="PRICE_FROM" class="form-label"
                            >{{ dg.SalTInvoice.PRICE }}
                            <help :tooltip="dg.SalTInvoice.priceHelp" />
                        </label>
                        <div class="d-flex gap-1 align-items-center">
                            <input-hankaku
                                v-model="form.PRICE_FROM"
                                inputType="number"
                                inputName="PRICE_FROM"
                                placeholder="0"
                            />
                            <span>〜</span>
                            <input-hankaku
                                v-model="form.PRICE_TO"
                                inputType="number"
                                inputName="PRICE_TO"
                                placeholder="10000"
                            ></input-hankaku>
                        </div>
                        <error-message
                            :error="errors.PRICE_FROM"
                        ></error-message>
                    </div>
                    <!-- 備考 -->
                    <div style="width: 240px; z-index: 1028">
                        <label for="NOTE" class="form-label"
                            >{{ dg.SalTInvoice.NOTE }}
                            <help :tooltip="dg.SalTInvoice.noteHelp" />
                        </label>
                        <input
                            type="text"
                            name="NOTE"
                            class="form-control form-control-sm"
                            v-model="form.NOTE"
                        />
                        <error-message :error="errors.NOTE"></error-message>
                    </div>
                    <!-- 商品コード -->
                    <div style="width: 360px; z-index: 1028">
                        <label for="PRODUCT_CODE" class="form-label">{{
                            dg.SalTSales.PRODUCT_NAME
                        }}</label>
                        <VueMultiselect
                            v-model="form.PRODUCT_CODE"
                            :custom-label="setProductLabel"
                            :options="productListArray"
                            label="name"
                            :close-on-select="false"
                            :taggable="false"
                            :multiple="true"
                            :searchable="false"
                            placeholder="選択してください"
                            selectLabel="選択"
                            selectedLabel="選択済"
                            deselectLabel="解除"
                            :limitText="(count) => `+${count}`"
                            :limit="1"
                        >
                        </VueMultiselect>
                    </div>
                    <!-- リセット・検索ボタン -->
                    <div
                        class="align-self-end d-flex gap-1 align-items-start justify-content-end"
                        style="flex: 1"
                    >
                        <!-- 明細表示トグル -->
                        <div class="align-self-center me-2">
                            <toggle-switch
                                v-model="form.showDetail"
                                @handle-toggle-change="handleToggleChange"
                                >{{ dg.ShowDetail }}</toggle-switch
                            >
                        </div>
                        <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                            @click="reset()"
                        >
                            {{ dg.Reset }}
                        </button>
                        <button type="submit" class="btn btn-primary btn-sm">
                            {{ dg.Search }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <invoice-index-table
        ref="table"
        v-if="!form.showDetail"
        v-model="invoices"
        v-model:formModelValue="form"
        :document-root="documentRoot"
        :dg="dg"
        :invoice-status-list="invoiceStatusList"
        :ids="ids"
        :product-list="productList"
        :rece-code-list="receCodeList"
        :session-name="sessionName"
        @handleTableScroll="handleTableScroll"
        @fetch="getInvoices"
    ></invoice-index-table>
    <invoice-detail-index-table
        ref="table"
        v-else
        v-model="invoiceDetails"
        v-model:formModelValue="form"
        :document-root="documentRoot"
        :dg="dg"
        :ids="detailIds"
        :invoice-status-list="invoiceStatusList"
        :product-list="productList"
        :rece-code-list="receCodeList"
        :session-name="sessionName"
        @handleTableScroll="handleTableScroll"
        @fetch="getInvoiceDetails"
    ></invoice-detail-index-table>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import VueMultiselect from "vue-multiselect";
import CsvButton from "../CsvButton.vue";
import InputDate from "../inputs/InputDate.vue";
import InputHankaku from "../inputs/InputHankaku.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import ToggleSwitch from "../inputs/ToggleSwitch.vue";
import Mixin from "../mixins/mixin";
import Help from "../utils/Help.vue";
import InvoiceDetailIndexTable from "./InvoiceDetailIndexTable.vue";
import InvoiceIndexTable from "./InvoiceIndexTable.vue";

export default {
    name: "InvoiceIndex",
    mixins: [Mixin],
    components: {
        FontAwesomeIcon,
        CsvButton,
        InputKokykIdWithButton,
        InputDate,
        InputHankaku,
        InvoiceIndexTable,
        InvoiceDetailIndexTable,
        VueMultiselect,
        ToggleSwitch,
        Help,
    },
    props: {
        dg: Object,
        delFlag: Object,
        documentRoot: String,
        invoiceStatusList: Object,
        receCodeList: Object,
        payKindList: Object,
        productList: Object,
        syzkList: Object,
        prefectureList: Object,
    },
    data() {
        return {
            form: {},
            invoices: [],
            invoiceDetails: [],
            errors: {},
            scroll: 0,
            sessionName: "invoiceIndex",
        };
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        this.form.showDetail
            ? await this.getInvoiceDetails()
            : await this.getInvoices();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        init() {
            // 検索条件
            (this.form = {
                START_DAY: moment().subtract(3, "M").format("YYYY-04-01"),
                END_DAY: moment()
                    .subtract(3, "M")
                    .add(1, "y")
                    .format("YYYY-03-31"),
                KOKYK_ID: "",
                CLNIC_NM: "",
                STATUS: ["0", "1"],
                IS_RECLAIMING: false,
                RECE_CODE: [],
                PRODUCT_CODE: [],
                SYZK_NO: [],
                PREFECTURE: [],
                showDetail: false,
                sort_key: "INV_DATE",
                sort_asc: false,
            }),
                // スクロール量
                (this.scroll = 0);
        },
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ["form", "scroll"];

            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach((key) => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key];
                    // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] });
                }
            });
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop });
        },
        getInvoicesOrDetails() {
            this.form.showDetail
                ? this.getInvoiceDetails()
                : this.getInvoices();
        },
        setCustomerInfo(mKokykLic) {
            const keys = ["KOKYK_ID", "CLNIC_NM"];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        getInvoices() {
            this.errors = {};
            return axios
                .get(`/invoice/search`, { params: this.form })
                .then((res) => {
                    this.invoices = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form });
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        // window.alert(this.dg.internal_error);
                    }
                });
        },
        async getInvoiceDetails() {
            this.errors = {};
            try {
                const res = await axios.get("/invoice_detail/search", {
                    params: this.form,
                });
                this.invoiceDetails = res.data.data;
                this.setSession(this.sessionName, { form: this.form });
                this.$refs.table.init();
            } catch (error) {
                if (error?.response?.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error?.response?.status === 500) {
                    const status = error.response.status;
                    window.alert(this.dg.internal_error);
                }
            }
        },
        reset() {
            this.init();
            this.getInvoices();
        },
        setProductLabel(code) {
            return `${code}/${this.productList[code]}`;
        },
        setStatusLabel(code) {
            return `${this.invoiceStatusList[code]}`;
        },
        setReceCodeLabel(code) {
            return `${this.receCodeList[code]}`;
        },
        setPayKindLabel(code) {
            return `${this.payKindList[code]}`;
        },
        setPrefectureLabel(code) {
            return `${this.prefectureList[code]}`;
        },
        setSyzkLabel(code) {
            return `${this.syzkList[code]}`;
        },
        handleToggleChange(toggleVal) {
            toggleVal ? this.getInvoiceDetails() : this.getInvoices();
        },
    },
    computed: {
        ids() {
            return this.invoices.map((invoice) => invoice.ID);
        },
        detailIds() {
            return this.invoiceDetails.map((detail) => detail.ID);
        },
        productListArray() {
            return Object.keys(this.productList);
        },
        statusListArray() {
            return Object.keys(this.invoiceStatusList);
        },
        receCodeListArray() {
            return Object.keys(this.receCodeList).sort();
        },
        payKindListArray() {
            return Object.keys(this.payKindList).sort();
        },
        prefectureListArray() {
            return Object.keys(this.prefectureList);
        },
        syzkListArray() {
            return Object.keys(this.syzkList).sort();
        },
    },
};
</script>
