<template>
    <form
        ref="form"
        @submit.prevent="getSales()"
        class="mb-2 position-relative"
    >
        <div class="position-absolute" style="top: -2.1rem; left: 6rem">
            <link-button :url="link.url">{{ link.name }}</link-button>
        </div>
        <small
            class="position-absolute"
            style="bottom: 2px; right: 0.5rem; z-index: 1"
            role="alert"
        >
            {{ dg.SalTSales.aboutContractMessage }}
        </small>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <!-- 検索フォーム（上段）-->
                        <div class="d-flex gap-1 align-items-start">
                            <!-- 開始日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.START_DAY"
                                :error="errors.START_DAY"
                                :label-attr="{
                                    for: 'START_DAY',
                                    title: dg.SALES_START_DAY,
                                }"
                            ></input-date>

                            <!-- 終了日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.END_DAY"
                                :error="errors.END_DAY"
                                :label-attr="{
                                    for: 'END_DAY',
                                    title: dg.END_DAY,
                                }"
                            ></input-date>

                            <!-- 顧客番号と検索ボタン -->
                            <input-kokyk-id-with-button
                                v-model="form.KOKYK_ID"
                                @set-customer="setCustomerInfo"
                                :dg="dg"
                                :del-flag="delFlag"
                                :divStyle="{ width: '110px' }"
                                :errors="errors"
                                :syzk-list="syzkList"
                            ></input-kokyk-id-with-button>
                            <div style="width: 130px">
                                <label for="CLNIC_NM" class="form-label">{{
                                    dg.MKokykLic.CLNIC_NM
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.CLNIC_NM"
                                />
                                <error-message
                                    :error="errors.CLNIC_NM"
                                ></error-message>
                            </div>
                            <!-- 所属団体 -->
                            <div style="width: 150px; z-index: 1029">
                                <label for="SYZK_NO" class="form-label">{{
                                    dg.MsSyzk.SYZK_NM
                                }}</label>
                                <VueMultiselect
                                    v-model="form.SYZK_NO"
                                    :custom-label="setSyzkLabel"
                                    :options="syzkListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                ></VueMultiselect>
                            </div>
                            <!-- 都道府県 -->
                            <div style="width: 135px; z-index: 1029">
                                <label for="PREFECTURE" class="form-label">{{
                                    dg.SalTSales.PREFECTURE
                                }}</label>
                                <VueMultiselect
                                    v-model="form.PREFECTURE"
                                    :custom-label="setPrefectureLabel"
                                    :options="prefectureListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.PREFECTURE"
                                ></error-message>
                            </div>
                            <!-- 状態 -->
                            <div style="width: 140px; z-index: 1029">
                                <label for="STATUS" class="form-label">{{
                                    dg.SalTSales.STATUS
                                }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="statusListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.STATUS"
                                ></error-message>
                            </div>
                            <!-- 終了予約 -->
                            <div
                                class="align-self-center"
                                style="margin: 1rem 0 0 -0.75rem"
                            >
                                <toggle-switch
                                    v-model="form.IS_SCHEDULED_TO_END"
                                    :id="'IS_SCHEDULED_TO_END'"
                                >
                                    {{ dg.SalTSales.IS_SCHEDULED_TO_END }}
                                </toggle-switch>
                            </div>
                            <!-- 請求方法 -->
                            <div style="width: 160px; z-index: 1029">
                                <label for="RECE_CODE" class="form-label">{{
                                    dg.SalTSales.RECE_CODE
                                }}</label>
                                <VueMultiselect
                                    v-model="form.RECE_CODE"
                                    :custom-label="setReceCodeLabel"
                                    :options="receCodeListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.RECE_CODE"
                                ></error-message>
                            </div>
                            <!-- 請求月 -->
                            <div style="width: 120px; z-index: 1029">
                                <label for="DRAW_DATE" class="form-label">{{
                                    dg.SalTSales.DRAW_DATE
                                }}</label>
                                <VueMultiselect
                                    v-model="form.DRAW_DATE"
                                    :custom-label="setDrawDateLabel"
                                    :options="
                                        [...Array(12)].map((_, i) => i + 1)
                                    "
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.DRAW_DATE"
                                ></error-message>
                            </div>

                            <!-- 次回請求月 -->
                            <input-date
                                style="width: 110px"
                                v-model="form.RECE_NDATE"
                                :error="errors.RECE_NDATE"
                                :label-attr="{
                                    for: 'RECE_NDATE',
                                    title: dg.SalTSales.RECE_NDATE,
                                }"
                                :input-attr="{ type: 'month' }"
                            ></input-date>
                        </div>
                        <!-- 検索フォーム（下段）-->
                        <div class="d-flex gap-1 align-items-start">
                            <!-- 契約開始日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.CONT_START_DAY"
                                :error="errors.CONT_START_DAY"
                                :label-attr="{
                                    for: 'CONT_START_DAY',
                                    title: dg.CONT_START_DAY,
                                }"
                            ></input-date>

                            <!-- 契約終了日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.CONT_END_DAY"
                                :error="errors.CONT_END_DAY"
                                :label-attr="{
                                    for: 'CONT_END_DAY',
                                    title: dg.END_DAY,
                                }"
                            ></input-date>
                            <!-- 顧客番号と検索ボタン -->
                            <input-kokyk-id-with-button
                                v-model="form.B_KOKYK_ID"
                                @set-customer="setBCustomerInfo"
                                :dg="dg"
                                :del-flag="delFlag"
                                :divStyle="{ width: '110px' }"
                                :errors="errors"
                                nameAttribute="B_KOKYK_ID"
                                :syzk-list="syzkList"
                            ></input-kokyk-id-with-button>
                            <div style="width: 130px">
                                <label for="B_CLNIC_NM" class="form-label">{{
                                    dg.SalTSales.B_CLNIC_NM
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.B_CLNIC_NM"
                                />
                                <error-message
                                    :error="errors.B_CLNIC_NM"
                                ></error-message>
                            </div>
                            <!-- 商品コード -->
                            <div style="width: 360px; z-index: 1028">
                                <label for="PRODUCT_CODE" class="form-label">{{
                                    dg.SalTSales.PRODUCT_NAME
                                }}</label>
                                <VueMultiselect
                                    v-model="form.PRODUCT_CODE"
                                    :custom-label="setProductLabel"
                                    :options="productListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                            </div>
                            <!-- リセット・検索ボタン -->
                            <div
                                class="align-self-end d-flex gap-1 align-items-start justify-content-end mb-2"
                                style="flex: 1"
                            >
                                <!-- 明細表示トグル -->
                                <div class="align-self-center me-2">
                                    <toggle-switch
                                        v-model="showDetail"
                                        @handle-toggle-change="
                                            handleToggleChange
                                        "
                                        >{{ dg.ShowDetail }}</toggle-switch
                                    >
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    @click="reset()"
                                >
                                    {{ dg.Reset }}
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                >
                                    {{ dg.Search }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <sales-index-table
        ref="table"
        v-model="salesList"
        :cont-kind-list="contKindList"
        :dg="dg"
        :document-root="documentRoot"
        :product-list="productList"
        :sales-status-list="salesStatusList"
        :session-name="sessionName"
        :sort="sort"
        :show-detail="showDetail"
        :ids="ids"
        @handleTableScroll="handleTableScroll"
    ></sales-index-table>
</template>

<script>
import moment from "moment";
import VueMultiselect from "vue-multiselect";
import CsvButton from "../CsvButton.vue";
import LinkButton from "../buttons/LinkButton.vue";
import InputDate from "../inputs/InputDate.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import ToggleSwitch from "../inputs/ToggleSwitch.vue";
import Mixin from "../mixins/mixin";
import SalesIndexTable from "../sales/SalesIndexTable.vue";

export default {
    name: "SalesIndex",
    mixins: [Mixin],
    components: {
        CsvButton,
        InputDate,
        InputKokykIdWithButton,
        LinkButton,
        SalesIndexTable,
        ToggleSwitch,
        VueMultiselect,
    },
    props: {
        contKind: Number,
        contKindList: Object,
        salesContractStatusList: Object,
        salesStatusList: Object,
        prefectureList: Object,
        dg: Object,
        delFlag: Object,
        documentRoot: String,
        productList: Object,
        receCodeList: Object,
        syzkList: Object,
    },
    data() {
        return {
            checkedSales: [],
            errors: {},
            form: {},
            link: {},
            salesList: [],
            scroll: 0,
            sessionName: this.contKind ? "contractIndex" : "salesIndex",
            showDetail: false,
            sort: {
                key: "SALES_DATE",
                asc: false,
            },
        };
    },
    async created() {
        // リンクセット
        this.setLink();
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getSales();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ["form", "showDetail", "scroll", "sort"];

            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach((key) => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key];
                    // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] });
                }
            });
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop });
        },
        init() {
            // 検索条件
            this.form = {
                KOKYK_ID: "",
                CLNIC_NM: "",
                CONT_KIND: "",
                RECE_NDATE: "",
                RECE_CODE: "",
                DRAW_DATE: "",
                PRODUCT_CODE: [],
                SYZK_NO: [],
                PREFECTURE: [],
                START_DAY: moment().subtract(3, "M").format("YYYY-04-01"),
                END_DAY: moment()
                    .subtract(3, "M")
                    .add(1, "y")
                    .format("YYYY-03-31"),
                CONT_START_DAY: "",
                CONT_END_DAY: "",
                STATUS: ["0", "1", "3"],
                IS_SCHEDULED_TO_END: false,
            };
            // 明細表示
            this.showDetail = false;
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: "SALES_DATE",
                asc: false,
            };
        },
        setLink() {
            if (this.contKind) {
                // 契約
                this.link.name = this.dg.contract.create;
                this.link.url = `${this.documentRoot}/contract/create`;
            } else {
                // 単品
                this.link.name = this.dg.sales.create;
                this.link.url = `${this.documentRoot}/sales/create`;
            }
        },
        setCustomerInfo(mKokykLic) {
            const keys = ["KOKYK_ID", "CLNIC_NM"];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        setBCustomerInfo(mKokykLic) {
            this.form.B_KOKYK_ID = mKokykLic.KOKYK_ID;
            this.form.B_CLNIC_NM = mKokykLic.CLNIC_NM;
        },
        getSales() {
            this.errors = {};
            const url = this.contKind ? `/contract/search` : `/sales/search`;
            return axios
                .get(url, { params: this.form })
                .then((res) => {
                    this.salesList = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form });
                })
                .then(() => {
                    this.$refs.table.init();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        edit(salesNo) {
            location.href = `${this.documentRoot}/sales/${salesNo}/edit`;
        },
        stock(salesNo) {
            location.href = `${this.documentRoot}/stock?SALES_NO=${salesNo}`;
        },
        reset() {
            this.init();
            this.$refs.table.init();
            this.getSales();
        },
        setProductLabel(code) {
            return `${code}/${this.productList[code]}`;
        },
        setPrefectureLabel(code) {
            return `${this.prefectureList[code]}`;
        },
        setStatusLabel(code) {
            return `${this.salesStatusList[code]}`;
        },
        setReceCodeLabel(code) {
            return `${this.receCodeList[code]}`;
        },
        setDrawDateLabel(code) {
            return `${code}月`;
        },
        setSyzkLabel(code) {
            return `${this.syzkList[code]}`;
        },
        handleToggleChange(toggleVal) {
            this.setSession(this.sessionName, { showDetail: toggleVal });
        },
    },
    computed: {
        ids() {
            return this.salesList.map((sales) => sales.SALES_NO);
        },
        productListArray() {
            return Object.keys(this.productList);
        },
        statusListArray() {
            return Object.keys(this.salesStatusList);
        },
        receCodeListArray() {
            return Object.keys(this.receCodeList).sort();
        },
        prefectureListArray() {
            return Object.keys(this.prefectureList);
        },
        syzkListArray() {
            return Object.keys(this.syzkList).sort();
        },
    },
};
</script>
