<template>
    <form ref="form">
        <div class="card mb-1">
            <div class="card-body py-1">
                <div class="row mb-2">
                    <!-- 見積番号 -->
                    <div class="col-1">
                        <label for="EST_NO" class="form-label form-label-sm">{{
                            dg.SalTEstimate.EST_NO
                        }}</label>
                        <input
                            readonly
                            name="EST_NO"
                            type="text"
                            class="form-control form-control-sm"
                            id="EST_NO"
                            :value="form.EST_NO"
                        />
                        <error-message :error="errors.EST_NO"></error-message>
                    </div>
                    <!-- 枝番 -->
                    <div class="col-1">
                        <label for="ENO" class="form-label">{{ dg.ENO }}</label>
                        <div class="d-flex">
                            <input
                                readonly
                                type="text"
                                name="ENO"
                                class="form-control form-control-sm text-end"
                                style="width: 70px"
                                :value="form.ENO"
                            />
                            <eno-buttons
                                :eno-check-axios="enoCheckAxios"
                                :move-to="moveTo"
                            ></eno-buttons>
                        </div>
                    </div>
                    <!-- 見積名称 -->
                    <div class="col-4">
                        <label for="E_NAME" class="form-label">{{
                            dg.SalTEstimate.E_NAME
                        }}</label>
                        <input
                            type="text"
                            name="E_NAME"
                            class="form-control form-control-sm"
                            id="E_NAME"
                            v-model="form.E_NAME"
                        />
                        <error-message :error="errors.E_NAME"></error-message>
                    </div>
                    <!-- 状態 -->
                    <div class="col-1" style="width: 150px">
                        <label for="STATUS" class="form-label">{{
                            dg.SalTEstimate.STATUS
                        }}</label>
                        <select
                            name="STATUS"
                            class="form-select form-select-sm"
                            id="STATUS"
                            v-model="form.STATUS"
                        >
                            <option
                                v-for="(
                                    estimateStatus, index
                                ) in estimateStatusList"
                                :key="index"
                                :value="index"
                            >
                                {{ estimateStatus }}
                            </option>
                        </select>
                        <error-message :error="errors.STATUS"></error-message>
                    </div>

                    <!-- 見積日 -->
                    <div class="col-1" style="width: 150px">
                        <label for="EST_DATE" class="form-label">{{
                            dg.SalTEstimate.EST_DATE
                        }}</label>
                        <input
                            type="date"
                            name="EST_DATE"
                            class="form-control form-control-sm"
                            id="EST_DATE"
                            @blur="focusOut"
                            v-model="form.EST_DATE"
                        />
                        <error-message :error="errors.EST_DATE"></error-message>
                    </div>
                    <!-- 見積期限 -->
                    <div class="col-1" style="width: 150px">
                        <label for="LMT_DATE" class="form-label"
                            >{{ dg.SalTEstimate.LMT_DATE }}
                        </label>
                        <input
                            type="date"
                            name="LMT_DATE"
                            class="form-control form-control-sm"
                            id="LMT_DATE"
                            @change="lmtDateChanged = true"
                            v-model="form.LMT_DATE"
                        />
                        <error-message :error="errors.LMT_DATE"></error-message>
                    </div>
                    <!-- 見積り担当者 -->
                    <div class="col-2" style="width: 200px">
                        <label for="PERSON_IN_CHARGE" class="form-label"
                            >{{ dg.SalTEstimate.PERSON_IN_CHARGE }}
                        </label>
                        <input
                            type="text"
                            name="PERSON_IN_CHARGE"
                            class="form-control form-control-sm"
                            id="PERSON_IN_CHARGE"
                            v-model="form.PERSON_IN_CHARGE"
                        />
                        <error-message
                            :error="errors.PERSON_IN_CHARGE"
                        ></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 顧客番号と検索ボタン -->
                    <input-kokyk-id-with-button
                        v-model="form.KOKYK_ID"
                        @set-customer="setCustomerInfo"
                        :dg="dg"
                        :del-flag="delFlag"
                        :errors="errors"
                        :syzk-list="syzkList"
                    ></input-kokyk-id-with-button>
                    <!-- 顧客名 -->
                    <div class="col-2">
                        <label for="CLNIC_NM" class="form-label">{{
                            dg.MKokykLic.CLNIC_NM
                        }}</label>
                        <input
                            type="text"
                            name="CLNIC_NM"
                            class="form-control form-control-sm"
                            id="CLNIC_NM"
                            v-model="form.CLNIC_NM"
                        />
                        <error-message :error="errors.CLNIC_NM"></error-message>
                    </div>
                    <!-- 院長名 -->
                    <div class="col-2">
                        <label for="INCHO_NM" class="form-label">{{
                            dg.MKokykLic.INCHO_NM
                        }}</label>
                        <input
                            type="text"
                            name="INCHO_NM"
                            class="form-control form-control-sm"
                            id="INCHO_NM"
                            v-model="form.INCHO_NM"
                        />
                        <error-message :error="errors.INCHO_NM"></error-message>
                    </div>
                    <!-- 郵便番号 -->
                    <div class="col-1">
                        <label for="ZIP_CD" class="form-label">{{
                            dg.MKokykLic.ZIP_CD
                        }}</label>
                        <input-hankaku
                            v-model="form.ZIP_CD"
                            inputType="text"
                            inputName="ZIP_CD"
                            inputId="ZIP_CD"
                        ></input-hankaku>
                        <error-message :error="errors.ZIP_CD"></error-message>
                    </div>
                    <!-- 住所 -->
                    <div class="col-4">
                        <label for="ADR" class="form-label">{{
                            dg.MKokykLic.ADR
                        }}</label>
                        <input
                            type="text"
                            name="ADR"
                            class="form-control form-control-sm"
                            id="ADR"
                            v-model="form.ADR"
                        />
                        <error-message :error="errors.ADR"></error-message>
                    </div>
                </div>
                <div class="row mb-2">
                    <!-- 備考1 -->
                    <div class="col-6">
                        <div class="row">
                            <div class="col-form-label col-md-5 col-form-label">
                                <label for="EST_NOTE1">{{
                                    dg.SalTEstimate.EST_NOTE1
                                }}</label>
                                <span class="form-text ms-1">{{
                                    dg.SalTEstimate.EST_NOTE1_help
                                }}</span>
                            </div>
                            <!-- 備考テンプレート -->
                            <label
                                class="col-md-2 col-form-label-sm text-end"
                                for="TEMPLATE"
                                >{{ dg.template }}</label
                            >
                            <div class="col-md-5">
                                <select
                                    name="TEMPLATE"
                                    class="form-select form-select-sm"
                                    id="TEMPLATE"
                                    v-model="template"
                                >
                                    <option value="">{{ dg.none }}</option>
                                    <option
                                        v-for="(
                                            template, index
                                        ) in templateList"
                                        :key="index"
                                        :value="index"
                                    >
                                        {{ template.TITLE }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <textarea
                            name="EST_NOTE1"
                            class="form-control form-control-sm"
                            id="EST_NOTE1"
                            v-model="form.EST_NOTE1"
                            rows="5"
                        ></textarea>
                    </div>
                    <!-- 備考2 -->
                    <div class="col-6">
                        <div class="col-form-label col-md-5 col-form-label">
                            <label for="EST_NOTE2" class="form-label">{{
                                dg.SalTEstimate.EST_NOTE2
                            }}</label>
                            <span class="form-text ms-1">{{
                                dg.SalTEstimate.EST_NOTE2_help
                            }}</span>
                        </div>
                        <textarea
                            name="EST_NOTE1"
                            class="form-control form-control-sm"
                            id="EST_NOTE2"
                            v-model="form.EST_NOTE2"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <div class="mb-2 row">
                    <!-- 見積書PDF -->
                    <div class="col-6">
                        <label for="pdf" class="form-label">{{
                            dg.SalTEstimate.PDF
                        }}</label>
                        <ul v-if="form.PDF">
                            <li>
                                <a
                                    :href="`${documentRoot}/storage/estimate/${form.PDF}`"
                                    :download="`${form.PDF}`"
                                    >{{ form.PDF }}</a
                                >
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <!-- ファイル -->
                        <file-upload
                            v-model="form"
                            v-model:filesModelValue="files"
                            :dg="dg"
                            :max-file-count="5"
                            :slice-num="25"
                            :document-root="documentRoot"
                        ></file-upload>
                    </div>
                </div>
                <div class="table-responsive">
                    <table
                        class="table table-sm mb-0"
                        style="table-layout: fixed"
                    >
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 1rem">
                                    {{ dg.SequentialNumber }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTEstimateDtl.PRODUCT_CODE }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTEstimateDtl.PRODUCT_NAME }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 4rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTEstimateDtl.UPRICE }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 3rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTEstimateDtl.CNT }}
                                </th>
                                <th
                                    class="text-center"
                                    style="
                                        width: 4rem;
                                        letter-spacing: 0.5rem;
                                        text-indent: 0.5rem;
                                    "
                                >
                                    {{ dg.SalTEstimateDtl.PRICE }}
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTEstimateDtl.EST_NOTE1
                                    }}<span class="form-text fw-normal ms-1">{{
                                        dg.SalTEstimate.EST_NOTE1_help
                                    }}</span>
                                </th>
                                <th class="text-center" style="width: 10rem">
                                    {{ dg.SalTEstimateDtl.EST_NOTE2
                                    }}<span class="form-text fw-normal ms-1">{{
                                        dg.SalTEstimate.EST_NOTE2_help
                                    }}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <estimate-detail-input
                                ref="detail"
                                v-for="(detail, index) in form.salTEstimateDtls"
                                :key="index"
                                v-model="form.salTEstimateDtls[index]"
                                :dg="dg"
                                :index="index"
                                :product-obj-list="productObjList"
                                @update:price="calcTotalPrice"
                                @splice-row="spliceRow"
                            ></estimate-detail-input>
                            <tr>
                                <!-- 小計 -->
                                <th colspan="4" class="border-bottom-0"></th>
                                <th
                                    colspan="1"
                                    class="border-bottom-0"
                                    style="letter-spacing: 1rem"
                                >
                                    {{ dg.SUBTOTAL }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{
                                        Number(form.PRICE).toLocaleString()
                                    }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                            <tr>
                                <!-- 消費税 -->
                                <th colspan="4" class="border-bottom-0"></th>
                                <th colspan="1" class="border-bottom-0">
                                    {{ dg.SalTEstimate.TAX }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{ Number(form.TAX).toLocaleString() }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                            <tr>
                                <!-- 合計金額 -->
                                <th colspan="4" class="border-bottom-0"></th>
                                <th colspan="1" class="border-bottom-0">
                                    {{ dg.SalTEstimate.TPRICE }}
                                </th>
                                <th class="text-end border-bottom-0">
                                    &yen;{{
                                        Number(form.TPRICE).toLocaleString()
                                    }}
                                </th>
                                <th colspan="2" class="border-bottom-0"></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="card mb-1" v-if="salTSaleses.length > 0">
            <div class="card-body">
                <div class="card-title">
                    {{ dg.SalTSales.SalTSales }}
                </div>
                <div class="d-flex align-items-center gap-4">
                    <ul>
                        <li v-for="sales in salTSaleses" :key="sales.SALES_NO">
                            <a
                                :href="`${documentRoot}/sales/${sales.SALES_NO}/edit`"
                                target="_blank"
                                >{{ sales.SALES_NO }}</a
                            >
                        </li>
                    </ul>
                    <div class="alert alert-info">
                        <font-awesome-icon icon="fa-solid fa-circle-info" />
                        {{ dg.SalTEstimate.salesExistsMessage }}
                    </div>
                </div>
            </div>
        </div>

        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- 通知ボタン -->
            <button
                type="button"
                class="btn btn-primary"
                v-if="edit"
                @click="notify"
            >
                {{ dg.Notify }}
            </button>
            <!-- 販売ボタン -->
            <new-tab-button
                v-if="edit"
                :alert-flag="changed"
                :alert-message="dg.changedMessage"
                :url="`${documentRoot}/sales/${form.ID}/estimate`"
                >{{ dg.SalTSales.SalTSales }}</new-tab-button
            >
            <!-- コピーボタン -->
            <copy-button
                v-if="edit"
                :alert-flag="changed"
                :alert-message="dg.changedMessage"
                :confirm-message="`見積番号${
                    this.form.EST_NO +
                    '-' +
                    this.form.ENO +
                    dg.copyConfirmMessage
                }`"
                :url="`${documentRoot}/estimate/${form.ID}/copy`"
            ></copy-button>
            <!-- 削除ボタン -->
            <button
                type="button"
                class="btn btn-danger"
                v-if="edit"
                @click="destroy"
            >
                {{ dg.Delete }}
            </button>
            <!-- 印刷ボタン -->
            <button
                v-if="edit"
                type="button"
                class="btn btn-primary"
                @click="print"
            >
                {{ dg.print }}
            </button>
            <!-- 中止ボタン -->
            <cancel-button
                :confirm-flag="changed"
                :confirm-message="dg.cancelMessage"
                :url="`${documentRoot}/estimate`"
                >{{ dg.cancel }}</cancel-button
            >
            <!-- 登録ボタン -->
            <button type="button" class="btn btn-primary" @click="register">
                {{ dg.register }}
            </button>
        </div>

        <modal-dialog-update-eno
            ref="dialog"
            :dg="dg"
            :modal-button-update="dg.SalTEstimate.update"
            :modal-button-create="dg.SalTEstimate.enoUpdate"
            @update="update"
            @create="create"
        ></modal-dialog-update-eno>

        <modal-dialog-date
            ref="dialogDate"
            :message="dg.SalTEstimate.changeLmtDateMessage"
            @yes="changeLmtDate"
            @no="calcDateDiff"
        ></modal-dialog-date>
    </form>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import ModalDialogDate from "../ModalDialogDate.vue";
import CancelButton from "../buttons/CancelButton.vue";
import CopyButton from "../buttons/CopyButton.vue";
import EnoButtons from "../buttons/EnoButtons.vue";
import NewTabButton from "../buttons/NewTabButton.vue";
import FileUpload from "../inputs/FileUpload.vue";
import InputHankaku from "../inputs/InputHankaku.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import ModalDialogUpdateEno from "../modals/ModalDialogUpdateEno.vue";
import EstimateDetailInput from "./EstimateDetailInput.vue";

export default {
    name: "EstimateInput",
    components: {
        CancelButton,
        CopyButton,
        EnoButtons,
        EstimateDetailInput,
        FileUpload,
        InputKokykIdWithButton,
        InputHankaku,
        ModalDialogUpdateEno,
        ModalDialogDate,
        NewTabButton,
        FontAwesomeIcon,
    },
    props: {
        dg: Object,
        data: Object,
        delFlag: Object,
        documentRoot: String,
        estimateStatusList: Object,
        productObjList: Array,
        syzkList: Object,
        templateList: Array,
    },
    data() {
        return {
            form: {},
            changed: false,
            lmtDateChanged: false,
            dateDiff: 0,
            edit: false,
            errors: [],
            files: [],
            oldEstDate: "",
            salTSaleses: [],
            template: "",
        };
    },
    created() {
        const { salTSaleses, ...data } = this.data;
        this.form = data;
        this.salTSaleses = salTSaleses ?? [];
        this.form.ENO = this.form.ENO ?? 1;
        this.form.EST_DATE =
            this.form.EST_DATE ?? moment().format("YYYY-MM-DD");
        this.form.LMT_DATE =
            this.form.LMT_DATE ?? moment().add(2, "w").format("YYYY-MM-DD");
        this.form.STATUS = this.form.STATUS ?? 0;
        this.setSalTEstimateDtls();
        this.edit = this.form.ID ? true : false;
        this.oldEstDate = this.form.EST_DATE;
    },
    mounted() {
        const unwatch = this.$watch(
            "form",
            () => {
                this.changed = true;
                unwatch();
            },
            { deep: true }
        );
    },
    methods: {
        spliceRow(index) {
            const keys = [
                "RNO",
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "PRICE",
                "EST_NOTE1",
                "EST_NOTE2",
            ];
            const defaultDetail = keys.reduce(
                (obj, key) => ({ ...obj, [key]: "" }),
                {}
            );

            const clone = [...this.form.salTEstimateDtls, defaultDetail];
            clone.splice(index, 1);

            this.form.salTEstimateDtls = clone;
        },
        focusOut() {
            if (this.oldEstDate !== this.form.EST_DATE) {
                if (this.lmtDateChanged) {
                    this.$refs.dialogDate.open();
                } else {
                    this.changeLmtDate();
                }
                this.oldEstDate = this.form.EST_DATE;
            }
        },
        getFormData() {
            const formData = new FormData(this.$refs.form);
            let data = this.makeData();

            Object.keys(data).forEach(function (key) {
                if (key == "salTEstimateDtls") {
                    data[key].forEach((item, index) => {
                        Object.keys(item).forEach(function (i) {
                            if (item[i] == null) {
                                formData.set(
                                    `salTEstimateDtls[${index}][${i}]`,
                                    ""
                                );
                            } else {
                                formData.set(
                                    `salTEstimateDtls[${index}][${i}]`,
                                    item[i]
                                );
                            }
                        });
                    });
                } else if (data[key] == null) {
                    formData.set(key, "");
                } else {
                    formData.set(key, data[key]);
                }
            });

            formData.set("FILE1", "");
            formData.set("FILE2", "");
            formData.set("FILE3", "");
            formData.set("FILE4", "");
            formData.set("FILE5", "");

            for (var i = 0; i < this.files.length; i++) {
                if (this.files[i].file) {
                    formData.set("FILE" + (i + 1), this.files[i].file);
                } else {
                    formData.set("FILE" + (i + 1), this.files[i].name);
                }
            }

            return formData;
        },
        destroy() {
            if (confirm(this.dg.deleteConfirmMessage)) {
                axios.delete(`/estimate/${this.form.ID}`).then((res) => {
                    location.href = res.data;
                });
            }
        },
        register() {
            this.edit ? this.$refs.dialog.open() : this.create();
        },
        create() {
            axios
                .post(`/estimate`, this.getFormData(), {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        update() {
            this.updateAxios()
                .then((res) => {
                    location.href = res.data;
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        print() {
            if (this.changed) {
                if (confirm(this.dg.changedPrintMessage)) {
                    // はいの場合
                    this.updateAxios()
                        .then(() => this.printAxios())
                        .then((res) => (location.href = res.data))
                        .catch((error) => {
                            if (error.response.status === 422) {
                                this.errors = error.response.data.errors;
                            } else if (error.response.status === 500) {
                                const status = error.response.status;
                                window.alert(this.dg.internal_error);
                            }
                        });
                }
            } else {
                this.printAxios().then((res) => (location.href = res.data));
            }
        },
        setSalTEstimateDtls() {
            let length = this.data.salTEstimateDtls.length;

            const keys = [
                "RNO",
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "PRICE",
                "EST_NOTE1",
                "EST_NOTE2",
            ];
            const defaultDetail = keys.reduce(
                (obj, key) => ({ ...obj, [key]: "" }),
                {}
            );

            while (length < 10) {
                length = this.form.salTEstimateDtls.push({ ...defaultDetail });
            }
        },
        calcDateDiff() {
            this.dateDiff = moment(this.form.LMT_DATE).diff(
                this.form.EST_DATE,
                "d"
            );
        },
        changeLmtDate() {
            this.form.LMT_DATE = moment(this.form.EST_DATE)
                .add(this.dateDiff, "d")
                .format("YYYY-MM-DD");
        },
        setCustomerInfo(mKokykLic) {
            const keys = [
                "KOKYK_ID",
                "CLNIC_NM",
                "INCHO_NM",
                "ZIP_CD",
                "ADR",
                "TEL",
            ];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        filterValidRow() {
            const checKeys = [
                "PRODUCT_CODE",
                "PRODUCT_NAME",
                "UPRICE",
                "CNT",
                "EST_NOTE1",
                "EST_NOTE2",
            ];
            let row = 1;

            for (let i = 9; i > 0; i--) {
                checKeys.some((check) => {
                    if (this.form.salTEstimateDtls[i][check]) {
                        row = i + 1;
                        return true;
                    }
                });
                if (row > 1) {
                    break;
                }
            }

            return row;
        },
        calcTotalPrice() {
            const price = this.form.salTEstimateDtls.reduce((acc, cur) => {
                return (acc += Number(cur.PRICE));
            }, 0);

            this.form.PRICE = price;
            this.form.TAX = Math.round(price * 0.1);
            this.form.TPRICE = Math.round(price * 1.1);
        },
        makeData() {
            let data = Object.assign({}, this.form);
            data.salTEstimateDtls = data.salTEstimateDtls.slice(
                0,
                this.filterValidRow()
            );

            return data;
        },
        updateAxios() {
            return axios.post(`/estimate/${this.data.ID}`, this.getFormData(), {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-HTTP-Method-Override": "PUT",
                },
            });
        },
        printAxios() {
            return axios.post(`/estimate/${this.form.ID}/pdf`);
        },
        enoCheckAxios() {
            return axios.get(`/estimate/${this.form.ID}/eno`);
        },
        moveTo(primaryKey) {
            location.href = `${this.documentRoot}/estimate/${primaryKey}/edit`;
        },
        notify() {
            axios.post(`/estimate/${this.form.ID}/notify`).then((response) => {
                alert("slackに通知しました");
            });
        },
    },
    watch: {
        "form.LMT_DATE": {
            handler() {
                this.calcDateDiff();
            },
        },
        template(newVal) {
            this.form.EST_NOTE1 = this.templateList[newVal].TEMPLATE;
        },
    },
};
</script>
