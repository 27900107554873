<template>
    <form ref="form" @submit.prevent="getInvoices()" class="mb-2">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="card-text d-flex gap-4 align-items-start">
                            <div style="width: 150px">
                                <label for="START_DAY" class="form-label">{{
                                    dg.START_DAY
                                }}</label>
                                <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="form.START_DAY"
                                />
                                <error-message
                                    :error="errors.START_DAY"
                                ></error-message>
                            </div>
                            <div style="width: 150px">
                                <label for="END_DAY" class="form-label">{{
                                    dg.END_DAY
                                }}</label>
                                <input
                                    type="date"
                                    class="form-control form-control-sm"
                                    v-model="form.END_DAY"
                                />
                                <error-message
                                    :error="errors.END_DAY"
                                ></error-message>
                            </div>
                            <div style="width: 160px">
                                <label for="STATUS" class="form-label">{{
                                    dg.SalTInvoice.STATUS
                                }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="Object.keys(this.salesStatusList)"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                ></VueMultiselect>
                                <error-message
                                    :error="errors.STATUS"
                                ></error-message>
                            </div>
                            <!-- 製品チェックボックス -->
                            <div>
                                <label for="DRAW_DATE" class="form-label">{{
                                    dg.SalTSales.PRODUCT_NAME
                                }}</label>
                                <div>
                                    <div
                                        class="form-check form-check-inline"
                                        v-for="(
                                            productName, productCode
                                        ) in contractProductList"
                                        :key="productCode"
                                    >
                                        <input
                                            class="form-check-input"
                                            name="PRODUCT_CODE_PREFIX[]"
                                            type="checkbox"
                                            :id="`inlineCheckbox${productCode}`"
                                            :value="productCode"
                                            v-model="form.PRODUCT_CODE_PREFIX"
                                        />
                                        <label
                                            class="form-check-label"
                                            :for="`inlineCheckbox${productCode}`"
                                            >{{ productName }}</label
                                        >
                                    </div>
                                </div>
                                <error-message
                                    :error="errors.PRODUCT_CODE_PREFIX"
                                ></error-message>
                            </div>
                            <!-- 請求月 -->
                            <div style="width: 120px; z-index: 1029">
                                <label for="DRAW_DATE" class="form-label">{{
                                    dg.SalTSales.DRAW_DATE
                                }}</label>
                                <VueMultiselect
                                    v-model="form.DRAW_DATE"
                                    :custom-label="setDrawDateLabel"
                                    :options="
                                        [...Array(12)].map((_, i) => i + 1)
                                    "
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.DRAW_DATE"
                                ></error-message>
                            </div>
                            <div
                                class="d-flex gap-1 align-items-start"
                                style="margin-top: 23px"
                            >
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    @click="reset()"
                                >
                                    {{ dg.Reset }}
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                >
                                    {{ dg.Search }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <invoice-check-table
        ref="table"
        v-model="list"
        v-model:formModelValue="form"
        :dg="dg"
        :product-list="productList"
        :years="years"
        :sales-status-list="salesStatusList"
        @handleTableScroll="handleTableScroll"
        @fetch="getInvoices"
    ></invoice-check-table>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import Mixin from "../mixins/mixin";
import invoiceCheckTable from "./InvoiceCheckTable.vue";

export default {
    name: "InvoiceCheck",
    mixins: [Mixin],
    components: {
        VueMultiselect,
        invoiceCheckTable,
    },
    props: {
        contractProductList: Object,
        dg: Object,
        documentRoot: String,
        productList: Object,
        salesStatusList: Object,
        salesStatusContract: String,
        contractProductLuckin: String,
    },
    data() {
        return {
            form: {},
            list: [],
            errors: {},
            years: [],
            scroll: 0,
            sessionName: "invoiceCheck",
        };
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getInvoices();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        init() {
            // 検索条件
            this.form = {
                START_DAY: "",
                END_DAY: "",
                PRODUCT_CODE_PREFIX: [this.contractProductLuckin],
                // 契約中
                STATUS: [this.salesStatusContract],
                DRAW_DATE: [],
                sort_key: "KOKYK_ID",
                sort_asc: true,
            };
            // スクロール量
            this.scroll = 0;
        },
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ["form", "scroll"];

            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach((key) => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key];
                    // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] });
                }
            });
        },
        getInvoices() {
            this.errors = {};
            return axios
                .get(`/invoice/check_search`, { params: this.form })
                .then((res) => {
                    this.list = res.data.list;
                    this.years = res.data.years;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        reset() {
            this.init();
            this.getInvoices();
        },
        setStatusLabel(code) {
            return `${this.salesStatusList[code]}`;
        },
        setDrawDateLabel(code) {
            return `${code}月`;
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop });
        },
    },
};
</script>
