<template>
    <form
        ref="form"
        @submit.prevent="getItems()"
        class="mb-2 position-relative"
    >
        <div class="position-absolute" style="top: -2.1rem; left: 6rem">
            <a
                href="#"
                class="btn btn-primary btn-sm"
                @click.prevent.stop="create()"
                >{{ dg.stock.create }}</a
            >
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex gap-1 align-items-start">
                            <!-- 開始日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.START_DAY"
                                :error="errors.START_DAY"
                                :label-attr="{
                                    for: 'START_DAY',
                                    title: dg.START_DAY,
                                }"
                            ></input-date>
                            <!-- 終了日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.END_DAY"
                                :error="errors.END_DAY"
                                :label-attr="{
                                    for: 'END_DAY',
                                    title: dg.END_DAY,
                                }"
                            ></input-date>
                            <!-- 顧客番号と検索ボタン -->
                            <input-kokyk-id-with-button
                                v-model="form.KOKYK_ID"
                                @set-customer="setCustomerInfo"
                                :dg="dg"
                                :del-flag="delFlag"
                                :divStyle="{ width: '110px' }"
                                :errors="errors"
                                :syzk-list="syzkList"
                            ></input-kokyk-id-with-button>
                            <div style="width: 180px">
                                <label for="CLNIC_NM" class="form-label">{{
                                    dg.MKokykLic.CLNIC_NM
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.CLNIC_NM"
                                />
                                <error-message
                                    :error="errors.CLNIC_NM"
                                ></error-message>
                            </div>
                            <!-- 状態 -->
                            <div style="width: 150px; z-index: 1029">
                                <label for="STATUS" class="form-label">{{
                                    dg.SalTSales.STATUS
                                }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="statusListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.STATUS"
                                ></error-message>
                            </div>
                            <!-- 備考 -->
                            <div style="width: 240px; z-index: 1028">
                                <label for="NOTE" class="form-label"
                                    >{{ dg.SalTInvoice.NOTE }}
                                    <help :tooltip="dg.SalTInvoice.noteHelp" />
                                </label>
                                <input
                                    type="text"
                                    name="NOTE"
                                    class="form-control form-control-sm"
                                    v-model="form.NOTE"
                                />
                                <error-message
                                    :error="errors.NOTE"
                                ></error-message>
                            </div>
                            <!-- 商品コード -->
                            <div style="width: 360px; z-index: 1028">
                                <label for="PRODUCT_CODE" class="form-label">{{
                                    dg.SalTSales.PRODUCT_NAME
                                }}</label>
                                <VueMultiselect
                                    v-model="form.PRODUCT_CODE"
                                    :custom-label="setProductLabel"
                                    :options="productListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                            </div>
                            <!-- リセット・検索ボタン -->
                            <div
                                class="align-self-end d-flex gap-1 align-items-start justify-content-end mb-2"
                                style="flex: 1"
                            >
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    @click="reset()"
                                >
                                    {{ dg.Reset }}
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                >
                                    {{ dg.Search }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <stock-index-table
        ref="table"
        v-model="items"
        :dg="dg"
        divHeight="700px"
        :stock-status-list="stockStatusList"
        :document-root="documentRoot"
        :session-name="sessionName"
        :sort="sort"
        @handleTableScroll="handleTableScroll"
    ></stock-index-table>
    <div class="mt-2">
        <csv-button
            :button-name="dg.csv"
            file-name="仕入一覧.csv"
            :ids="ids"
            url="/stock/csv"
        ></csv-button>
        <csv-button
            class="ms-2"
            :button-name="`経理csv`"
            file-name="仕入経理.txt"
            :ids="ids"
            url="/stock/csv/account"
        ></csv-button>
    </div>
</template>

<script>
import moment from "moment";
import VueMultiselect from "vue-multiselect";
import CsvButton from "../CsvButton.vue";
import InputDate from "../inputs/InputDate.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import Mixin from "../mixins/mixin";
import StockIndexTable from "./StockIndexTable.vue";

export default {
    name: "StockIndex",
    mixins: [Mixin],
    components: {
        CsvButton,
        InputDate,
        StockIndexTable,
        VueMultiselect,
        InputKokykIdWithButton,
    },
    props: {
        dg: Object,
        delFlag: Object,
        documentRoot: String,
        productList: Object,
        stockStatusList: Object,
        syzkList: Object,
    },
    data() {
        return {
            form: {},
            items: [],
            errors: {},
            sort: {},
            sessionName: "stockIndex",
        };
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getItems();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ["form", "scroll", "sort"];

            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach((key) => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key];
                    // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] });
                }
            });
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop });
        },
        init() {
            // 検索条件
            this.form = {
                START_DAY: moment().subtract(3, "M").format("YYYY-04-01"),
                END_DAY: moment()
                    .subtract(3, "M")
                    .add(1, "y")
                    .format("YYYY-03-31"),
                KOKYK_ID: "",
                CLNIC_NM: "",
                STATUS: "",
                PRODUCT_CODE: [],
                NOTE: "",
            };
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: "STOCK_DATE",
                asc: false,
            };
        },
        handleScroll(e) {
            this.setSession(this.sessionName.scroll, e.target.scrollTop);
        },
        setCustomerInfo(mKokykLic) {
            const keys = ["KOKYK_ID", "CLNIC_NM"];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        getItems() {
            this.errors = {};
            let params = new URLSearchParams(location.search);

            if (params.get("SALES_NO")) {
                this.form.SALES_NO = params.get("SALES_NO");
                this.form.START_DAY = "";
                this.form.END_DAY = "";

                const url = new URL(window.location);
                url.search = "";
                history.pushState({}, "", url.href);
            }
            return axios
                .get(`/stock/search`, { params: this.form })
                .then((res) => {
                    this.items = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form });

                    if (this.form.SALES_NO) {
                        this.form.SALES_NO = "";
                    }
                })
                .then(() => {
                    this.$refs.table.init();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        create() {
            location.href = `${this.documentRoot}/stock/create`;
        },
        reset() {
            this.init();
            this.$refs.table.init();
            this.getItems();
        },
        setProductLabel(code) {
            return `${code}/${this.productList[code]}`;
        },
        setStatusLabel(code) {
            return `${this.stockStatusList[code]}`;
        },
    },
    computed: {
        ids() {
            return this.items.map((item) => item.STOCK_NO);
        },
        productListArray() {
            return Object.keys(this.productList);
        },
        statusListArray() {
            return Object.keys(this.stockStatusList);
        },
    },
};
</script>
