<template>
    <form
        ref="form"
        @submit.prevent="getEstimates()"
        class="mb-2 position-relative"
    >
        <div class="position-absolute" style="top: -2.1rem; left: 6rem">
            <link-button :url="`${documentRoot}/estimate/create`">{{
                dg.estimate.create
            }}</link-button>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex gap-1 align-items-start">
                            <!-- 開始日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.START_DAY"
                                :error="errors.START_DAY"
                                :label-attr="{
                                    for: 'START_DAY',
                                    title: dg.START_DAY,
                                }"
                            ></input-date>
                            <!-- 終了日 -->
                            <input-date
                                style="width: 115px"
                                v-model="form.END_DAY"
                                :error="errors.END_DAY"
                                :label-attr="{
                                    for: 'END_DAY',
                                    title: dg.END_DAY,
                                }"
                            ></input-date>
                            <!-- 顧客番号と検索ボタン -->
                            <input-kokyk-id-with-button
                                v-model="form.KOKYK_ID"
                                @set-customer="setCustomerInfo"
                                :dg="dg"
                                :del-flag="delFlag"
                                :divStyle="{ width: '110px' }"
                                :errors="errors"
                                :syzk-list="syzkList"
                            ></input-kokyk-id-with-button>
                            <!-- 顧客名 -->
                            <div style="width: 180px">
                                <label for="CLNIC_NM" class="form-label">{{
                                    dg.MKokykLic.CLNIC_NM
                                }}</label>
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="form.CLNIC_NM"
                                />
                                <error-message
                                    :error="errors.CLNIC_NM"
                                ></error-message>
                            </div>

                            <!-- 状態 -->
                            <div style="width: 160px; z-index: 1029">
                                <label for="STATUS" class="form-label">{{
                                    dg.SalTEstimate.STATUS
                                }}</label>
                                <VueMultiselect
                                    v-model="form.STATUS"
                                    :custom-label="setStatusLabel"
                                    :options="statusListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="未選択"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.STATUS"
                                ></error-message>
                            </div>

                            <!-- 商品コード -->
                            <div style="width: 360px; z-index: 1029">
                                <label for="PRODUCT_CODE" class="form-label">{{
                                    dg.SalTSales.PRODUCT_NAME
                                }}</label>
                                <VueMultiselect
                                    v-model="form.PRODUCT_CODE"
                                    :custom-label="setProductLabel"
                                    :options="productListArray"
                                    label="name"
                                    :close-on-select="false"
                                    :taggable="false"
                                    :multiple="true"
                                    :searchable="false"
                                    placeholder="選択してください"
                                    selectLabel="選択"
                                    selectedLabel="選択済"
                                    deselectLabel="解除"
                                    :limitText="(count) => `+${count}`"
                                    :limit="1"
                                >
                                </VueMultiselect>
                                <error-message
                                    :error="errors.PRODUCT_CODE"
                                ></error-message>
                            </div>
                            <!-- リセット・検索ボタン -->
                            <div
                                class="align-self-end d-flex gap-1 align-items-start justify-content-end mb-2"
                                style="flex: 1"
                            >
                                <!-- 明細表示トグル -->
                                <div class="align-self-center me-2">
                                    <toggle-switch
                                        v-model="showDetail"
                                        @handle-toggle-change="
                                            handleToggleChange
                                        "
                                        >{{ dg.ShowDetail }}</toggle-switch
                                    >
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    @click="reset()"
                                >
                                    {{ dg.Reset }}
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-sm"
                                >
                                    {{ dg.Search }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <estimate-index-table
        ref="table"
        v-model="estimates"
        :dg="dg"
        :document-root="documentRoot"
        :estimate-status-list="estimateStatusList"
        :product-list="productList"
        :session-name="sessionName"
        :sort="sort"
        :show-detail="showDetail"
        @handleTableScroll="handleTableScroll"
    ></estimate-index-table>

    <csv-button
        :button-name="csvInfo.buttonTitle"
        :file-name="csvInfo.fileName"
        :ids="ids"
        :url="csvInfo.url"
    ></csv-button>
</template>

<script>
import moment from "moment";
import VueMultiselect from "vue-multiselect";
import CsvButton from "../CsvButton.vue";
import LinkButton from "../buttons/LinkButton.vue";
import InputDate from "../inputs/InputDate.vue";
import InputKokykIdWithButton from "../inputs/InputKokykIdWithButton.vue";
import ToggleSwitch from "../inputs/ToggleSwitch.vue";
import Mixin from "../mixins/mixin";
import EstimateIndexTable from "./EstimateIndexTable.vue";

export default {
    name: "EstimateIndex",
    mixins: [Mixin],
    components: {
        CsvButton,
        EstimateIndexTable,
        InputDate,
        InputKokykIdWithButton,
        LinkButton,
        VueMultiselect,
        ToggleSwitch,
    },
    props: {
        dg: Object,
        documentRoot: String,
        delFlag: Object,
        estimateStatusList: Object,
        productList: Object,
        syzkList: Object,
    },
    data() {
        return {
            form: {},
            showDetail: false,
            estimates: [],
            errors: {},
            sort: {},
            scroll: 0,
            sessionName: "estimateIndex",
        };
    },
    async created() {
        // プロパティ初期化
        this.init();
        // セッションの操作
        this.sessionHandler();
        // 一覧の取得
        await this.getEstimates();
        // 指定値までスクロール
        this.$refs.table.scroll(this.scroll);
    },
    methods: {
        sessionHandler() {
            const sessionData = this.getSession(this.sessionName);
            const sessionDataKeys = ["form", "showDetail", "scroll", "sort"];

            // セッションのデータキーを順に確認し、
            sessionDataKeys.forEach((key) => {
                // キーが存在する場合、セッションから代入
                if (key in sessionData) {
                    this[key] = sessionData[key];
                    // キーが存在しない場合、セッションに値を保存
                } else {
                    this.setSession(this.sessionName, { [key]: this[key] });
                }
            });
        },
        init() {
            // 検索条件
            this.form = {
                START_DAY: moment().subtract(3, "M").format("YYYY-04-01"),
                END_DAY: moment()
                    .subtract(3, "M")
                    .add(1, "y")
                    .format("YYYY-03-31"),
                KOKYK_ID: "",
                CLNIC_NM: "",
                STATUS: ["0"],
                PRODUCT_CODE: [],
            };
            // 明細表示
            this.showDetail = false;
            // スクロール量
            this.scroll = 0;
            // ソート
            this.sort = {
                key: "EST_DATE",
                asc: false,
            };
        },
        handleTableScroll(e) {
            this.setSession(this.sessionName, { scroll: e.target.scrollTop });
        },
        setCustomerInfo(mKokykLic) {
            const keys = ["KOKYK_ID", "CLNIC_NM"];

            for (const key of keys) {
                this.form[key] = mKokykLic[key];
            }
        },
        getEstimates() {
            this.errors = {};
            return axios
                .get(`/estimate/search`, { params: this.form })
                .then((res) => {
                    this.estimates = res.data.data;
                    // 検索条件をセッション保存
                    this.setSession(this.sessionName, { form: this.form });
                })
                .then(() => {
                    this.$refs.table.init();
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    } else if (error.response.status === 500) {
                        const status = error.response.status;
                        window.alert(this.dg.internal_error);
                    }
                });
        },
        reset() {
            this.init();
            this.$refs.table.init();
            this.getEstimates();
        },
        setProductLabel(code) {
            return `${code}/${this.productList[code]}`;
        },
        setStatusLabel(code) {
            return `${this.estimateStatusList[code]}`;
        },
        handleToggleChange(toggleVal) {
            this.setSession(this.sessionName, { showDetail: toggleVal });
        },
    },
    computed: {
        ids() {
            return this.estimates.map((estimate) => estimate.ID);
        },
        productListArray() {
            return Object.keys(this.productList);
        },
        statusListArray() {
            return Object.keys(this.estimateStatusList);
        },
        csvInfo() {
            return this.showDetail
                ? {
                      fileName: "見積明細一覧.csv",
                      url: "/estimate/csv/detail",
                      buttonTitle: this.dg.csvDetail,
                  }
                : {
                      fileName: "見積一覧.csv",
                      url: "/estimate/csv/index",
                      buttonTitle: this.dg.csv,
                  };
        },
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect {
    font-size: 0.9rem;
}
.multiselect__tag {
    min-height: 0;
    padding: 4px 26px 4px 10px;
    margin-right: 2px;
    max-width: calc(100% - 25px);
}
.multiselect__tags {
    height: 32px;
    min-height: 25px;
    padding: 4px 20px 0 8px;
}

.multiselect__placeholder {
    margin-bottom: 0;
}
.multiselect__select {
    width: 25px;
}
</style>
